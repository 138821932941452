




















































































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      language: "language",
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      url: "url",
      oss: "oss"
    })
  },
  methods: {},
  mounted() {
    // if (!this.isCN) {
    //   window.open(
    //     "https://store.angrymiao.com/products/angry-miao-cybercharge-35w",
    //     "_blank"
    //   );
    //   this.$router.back();
    // }
  }
});
